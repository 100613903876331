import { Amplify } from 'aws-amplify'
import { unstable_batchedUpdates } from 'react-dom'
import { addUtmCodes } from './src/store/useUtmCodeStore'
export { default as wrapRootElement } from './src/components/gatsby/wrapRootElement'

require("./src/styles/multiDayTab.css")
require("./src/styles/globalGiving.css")
require("./src/styles/speakersPage.css")
require("./src/styles/styles.css")

Amplify.Logger.LOG_LEVEL = 'INFO';
Amplify.configure({
  'aws_appsync_graphqlEndpoint': process.env.GATSBY_GRAPLQL_ENDPOINT,
  'aws_appsync_apiKey': process.env.GATSBY_GRAPLQL_API_KEY,
  'aws_appsync_region': 'us-east-1',
  'aws_appsync_authenticationType': 'API_KEY',
  Auth: {
    region: process.env.GATSBY_USER_POOL_REGION,
    clientMetadata: { eventId: process.env.GATSBY_EVENT_ID },
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_USER_POOL_WEB_CLIENT_ID,
    storage: localStorage
  }
})

/**
 * Called when the user changes routes, including on the initial load of the app
 */

export const onRouteUpdate = ({ location }) => {
  unstable_batchedUpdates(() => {
    addUtmCodes(location.search)
  })
}