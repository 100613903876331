import { Auth } from "aws-amplify";
import { trackMetric } from "../";
import { generatePassword } from "../../hooks/useAuth/useAuth";
import { EventBriteUser } from "../eventbrite";

const eventId = process.env.GATSBY_EVENT_ID || ""; // If undefined there will be a type-check error
const allowedErrors = ["UsernameExistsException", "InvalidParameterException"];

export const registerV2Alpha = async (
  { email, ...user }: EventBriteUser,
  params: Record<string, string>
) => {
  try {
    const config = {
      username: email.toLowerCase(),
      password: generatePassword(),
      attributes: {
        email,
      },
      clientMetadata: {
        eventId,
        ...user,
        ...params,
      },
    };

    const cognitoUser = await Auth.signUp(config);

    await trackMetric({
      metricType: "registration",
      payload: {
        email,
        eventId,
        ...user,
        ...params,
      },
    });

    console.log("config", config, "cognitoUser", cognitoUser);
    return cognitoUser;
  } catch (error: any) {
    console.log("error:", error);
    if (!allowedErrors.includes(error?.name)) {
      throw Error(error?.message);
    }
  }
};

export const register = async ({ formData, context }, onFailure: Function) => {
  if (!formData) {
    throw Error(
      `Expected SignUpFormData type but got underfined while signing up.`
    );
  }

  const { email, ...userData } = formData;
  try {
    const params = {
      username: email.toLowerCase(),
      password: generatePassword(),
      clientMetadata: {
        eventId,
        groupId: eventId,
        ...userData,
        ...context,
      },
      validationData: [],
    };

    trackMetric({
      metricType: "registration",
      payload: {
        email,
        ...userData,
      },
    });

    await Auth.signUp(params);

    return logInWithEmail({ email }, () => {
      throw new Error(
        "Registration was completed but failed to auto sign-in. Please try to login."
      );
    });
  } catch (error: any) {
    if (error?.name !== "UsernameExistsException") {
      onFailure && onFailure(error?.message);
      throw Error(error?.message);
    }

    return logInWithEmail({ email }, () => {
      throw new Error(
        "Registration was completed but failed to auto sign-in. Please try to login."
      );
    });
  }
};

export const logIn = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  return null;
};

export const logInWithEmail = async (
  { email }: { email: string },
  onFailure: Function
) => {
  const params = {
    username: email.toLowerCase(),
    password: "",
    validationData: {
      eventId,
    },
  };
  const user = await Auth.signIn(params);
  user &&
    trackMetric({
      metricType: "login",
      payload: {
        email: email.toLowerCase(),
      },
    });
  return user;
};

export const logOut = async () => {
  return Auth.signOut();
};

export const currentUser = async () => {
  return Auth.currentAuthenticatedUser({
    bypassCache: false,
  });
};
export const currentSession = async () => {};

enum AuthErrorStrings {
  InvalidEmail = "Incorrect username or password.",
}

export const handleSignUpErrors = (error: any) => {
  let message = error?.message || "Error during Auth.SignUp";
  switch (message) {
    case AuthErrorStrings.InvalidEmail:
      message = "Invalid email, please try again";
      break;
    default:
      break;
  }
  throw new Error(message);
};
