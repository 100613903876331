import React, {
  createContext,
  FC,
  ReactNode,
  useState
} from "react";
import useAuth from "../hooks/useAuth";

const defaultState = {
  initialState: false,
  opened: false,
  toggleModal: () => undefined,
  openModal: () => undefined,
  closeModal: () => undefined,
  signOut: () => undefined,
};

export interface IAuthModalContext {
  opened: boolean;
  toggleModal: () => void;
  openModal: () => void;
  closeModal: () => void;
  signOut: () => void;
}

export interface AuthProviderProps {
  children: ReactNode;
  authRequired?: boolean;
}

export const AuthModalContext = createContext<IAuthModalContext>(defaultState);

export const AuthModalProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, setState] = useState<boolean>(false);
  const { user, loading, loggedOut, signOut } = useAuth();

  const closeModal = () => setState(false);
  const toggleModal = () => setState(!state);
  const openModal = () => setState(true);

  const value = {
    opened: state,
    toggleModal,
    openModal,
    closeModal,
    signOut,
  };

  return (
    <AuthModalContext.Provider value={value}>
      {children}
    </AuthModalContext.Provider>
  );
};

export interface IUseAuthModalContext {
  /**
   * Show modal if user is not authenticated.
   * It will also reopen modal after user signOut
   * */
  authRequired?: boolean;
  redirectOnSignIn?: string;
  redirectOnSignOut?: string;
}

export default AuthModalContext;
